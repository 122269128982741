<template>
  <nav
    class="right_bg close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-w-2/12 z-10"
    v-bind:style="{ right: toggle_right_Sidebar + 'rem' }"
  >
    <div class="">
      <div class="flex bg-emerald-500 justify-between px-2 py-0 mneminics_mt">
        <div class="righ_side_head_">
          <p class="sidebar-heading">Hydraulic Calculator</p>
        </div>
        <div>
          <svg
            v-on:click="closeNavigation"
            class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
            style="text-align: right"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <path
              id="close_2_"
              data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_slug"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/shared_vision.png" alt="" />
            Slug Input
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureMud"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>
      <div
        v-bind:style="{ display: isExpandFeatureSlug ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="px-2 py-2">
          <div>
            <hr class="mb-2 hrEleColor" />
            <p class="ml-2 text-xs textColorClass">bbls of slug required</p>
            <hr class="mt-2 mb-2 hrEleColor" />

            <!-- <div class="flex flex-col mt-3">
              <p class="label_heading">Dry Pipe Length (FT)</p>
              <input
                type="number"
                id="valueColor"
                class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                maxlength="15"
                @input="changeDrillPipeLengthHandler($event)"
                :value="dry_pipe_length"
              />
            </div> -->

            <div class="flex gap-2">
              <div class="flex pb-2 items-start flex-col" style="width: 70%">
                <p class="label_heading">Dry Pipe Length</p>
                <input
                  type="number"
                  id="valueColor"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  maxlength="15"
                  @input="changeDrillPipeLengthHandler($event)"
                  :value="dry_pipe_length"
                  style="width: 95%"
                />
              </div>
              <div style="width: 30%">
                <UnitSelect
                  :display-id="displayId"
                  :widget-id="widgetId"
                  :mnemonic="'dmea'"
                  :pt="'pt-0'"
                  :category="'Length'"
                  :is-custom-events="true"
                  :reverse="true"
                  :interChange="true"
                  :standaredUnit="
                    mappingDetails.dry_pipe_length_hyd.standard_units
                  "
                  :isMetrics="isWellMetrics"
                  :selected-val="
                    unitConversion?.dry_pipe_length_hyd?.unit &&
                    unitConversion?.dry_pipe_length_hyd?.unit != ''
                      ? unitConversion.dry_pipe_length_hyd.unit
                      : isWellMetrics?mappingDetails.dry_pipe_length_hyd.METRIC:mappingDetails.dry_pipe_length_hyd.IMPERIAL
                  "
                  @change_unit_handler="
                    (val) => changeUnitHandler('dry_pipe_length_hyd', val)
                  "
                />
              </div>
            </div>

            <div class="flex gap-2">
              <div class="flex pb-2 items-start flex-col" style="width: 70%">
                <p class="label_heading">Slug Weight</p>
                <input
                  type="number"
                  id="valueColor"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  :value="slug_weight"
                  maxlength="15"
                  @input="changeSlugWeightHandler($event)"
                  style="width: 95%"
                />
              </div>
              <div style="width: 30%">
                <UnitSelect
                  :display-id="displayId"
                  :widget-id="widgetId"
                  :mnemonic="'dmea'"
                  :pt="'pt-0'"
                  :category="'MW-Density gradients'"
                  :is-custom-events="true"
                  :reverse="true"
                  :interChange="true"
                  :standaredUnit="
                    mappingDetails.slug_weight_hyd.standard_units
                  "
                  :isMetrics="isWellMetrics"
                  :selected-val="
                    unitConversion?.slug_weight_hyd?.unit &&
                    unitConversion?.slug_weight_hyd?.unit != ''
                      ? unitConversion.slug_weight_hyd.unit
                      : isWellMetrics?mappingDetails.slug_weight_hyd.METRIC:mappingDetails.slug_weight_hyd.IMPERIAL
                  "
                  @change_unit_handler="
                    (val) => changeUnitHandler('slug_weight_hyd', val)
                  "
                />
              </div>
            </div>
            <!-- <div class="flex flex-col mt-3">
              <p class="label_heading">Slug Weight (ppg)</p>
              <input
                type="number"
                id="valueColor"
                class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                :value="slug_weight"
                maxlength="15"
                @input="changeSlugWeightHandler($event)"
              />
            </div> -->
            <hr class="mt-2 mb-2 hrEleColor" />
            <p class="ml-2 text-xs textColorClass">Weight of slug input</p>
            <hr class="mt-2 mb-2 hrEleColor" />

            <div class="flex gap-2">
              <div class="flex pb-2 items-start flex-col" style="width: 70%">
                <p class="label_heading">Dry Pipe Length</p>
                <input
                  type="number"
                  id="valueColor"
                  maxlength="15"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  @input="changeSlugDrillPipeLengthHandler($event)"
                  :value="slug_dry_pipe_length"
                  style="width: 95%"
                />
              </div>
              <div style="width: 30%">
                <UnitSelect
                  :display-id="displayId"
                  :widget-id="widgetId"
                  :mnemonic="'dmea'"
                  :pt="'pt-0'"
                  :category="'Length'"
                  :is-custom-events="true"
                  :reverse="true"
                  :interChange="true"
                  :standaredUnit="
                    mappingDetails.slug_dry_pipe_length_hyd.standard_units
                  "
                  :isMetrics="isWellMetrics"
                  :selected-val="
                    unitConversion?.slug_dry_pipe_length_hyd?.unit &&
                    unitConversion?.slug_dry_pipe_length_hyd?.unit != ''
                      ? unitConversion.slug_dry_pipe_length_hyd.unit
                      : isWellMetrics?mappingDetails.slug_dry_pipe_length_hyd.METRIC:mappingDetails.slug_dry_pipe_length_hyd.IMPERIAL
                  "
                  @change_unit_handler="
                    (val) => changeUnitHandler('slug_dry_pipe_length_hyd', val)
                  "
                />
              </div>
            </div>
            <!-- <div class="flex flex-col mt-3">
              <p class="label_heading">Dry Pipe Length (FT)</p>
              <input
                type="number"
                id="valueColor"
                maxlength="15"
                class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                @input="changeSlugDrillPipeLengthHandler($event)"
                :value="slug_dry_pipe_length"
              />
            </div> -->

            <!-- <div class="flex flex-col mt-3">
              <p class="label_heading">Slug Volume (BBLS)</p>
              <input
                type="number"
                id="valueColor"
                class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                :value="slug_volume"
                maxlength="15"
                @input="changeSlugVolumeHandler($event)"
              />
            </div> -->
            <div class="flex gap-2">
              <div class="flex pb-2 items-start flex-col" style="width: 70%">
                <p class="label_heading">Slug Volume</p>
                <input
                  type="number"
                  id="valueColor"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  :value="slug_volume"
                  maxlength="15"
                  @input="changeSlugVolumeHandler($event)"
                  style="width: 95%"
                />
              </div>
              <div style="width: 30%">
                <UnitSelect
                  :display-id="displayId"
                  :widget-id="widgetId"
                  :mnemonic="'dmea'"
                  :pt="'pt-0'"
                  :category="'Volume'"
                  :is-custom-events="true"
                  :reverse="true"
                  :interChange="true"
                  :standaredUnit="
                    mappingDetails.slug_volume_hyd.standard_units
                  "
                  :isMetrics="isWellMetrics"
                  :selected-val="
                    unitConversion?.slug_volume_hyd?.unit &&
                    unitConversion?.slug_volume_hyd?.unit != ''
                      ? unitConversion.slug_volume_hyd.unit
                      : isWellMetrics?mappingDetails.slug_volume_hyd.METRIC:mappingDetails.slug_volume_hyd.IMPERIAL
                  "
                  @change_unit_handler="
                    (val) => changeUnitHandler('slug_volume_hyd', val)
                  "
                />
              </div>
            </div>

            <hr class="mt-2 mb-2 hrEleColor" />
            <p class="ml-2 text-xs textColorClass">
              Vol, Height & Press gained due to slug
            </p>
            <hr class="mt-2 mb-2 hrEleColor" />
            <div class="flex gap-2">
              <div class="flex pb-2 items-start flex-col" style="width: 70%">
                <p class="label_heading">Annular Capacity</p>
                <input
                  type="number"
                  id="valueColor"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  :value="annular_capacity"
                  maxlength="15"
                  @input="changeAnnulusVolumeHandler($event)"
                  style="width: 95%"
                />
              </div>
              <div style="width: 30%">
                <UnitSelect
                  :display-id="displayId"
                  :widget-id="widgetId"
                  :mnemonic="'dmea'"
                  :pt="'pt-0'"
                  :category="'Displacement'"
                  :is-custom-events="true"
                  :reverse="true"
                  :interChange="true"
                  :standaredUnit="
                    mappingDetails.annular_capacity_hyd.standard_units
                  "
                  :isMetrics="isWellMetrics"
                  :selected-val="
                    unitConversion?.annular_capacity_hyd?.unit &&
                    unitConversion?.annular_capacity_hyd?.unit != ''
                      ? unitConversion.annular_capacity_hyd.unit
                      : isWellMetrics?mappingDetails.annular_capacity_hyd.METRIC:mappingDetails.annular_capacity_hyd.IMPERIAL
                  "
                  @change_unit_handler="
                    (val) => changeUnitHandler('annular_capacity_hyd', val)
                  "
                />
              </div>
            </div>

            <!-- <div class="flex flex-col mt-3">
              <p class="label_heading">Annular Capacity (FT/BBL)</p>
              <input
                type="number"
                id="valueColor"
                class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                :value="annular_capacity"
                maxlength="15"
                @input="changeAnnulusVolumeHandler($event)"
              />
            </div> -->

            <!-- <div class="flex flex-col mt-3">
              <p class="label_heading">Annular Slug Volume (BBLS)</p>
              <input
                type="number"
                id="valueColor"
                class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                :value="annular_slug_volume"
                maxlength="15"
                @input="changeAnnulusSlugVolumeHandler($event)"
              />
            </div> -->
            <div class="flex gap-2">
              <div class="flex pb-2 items-start flex-col" style="width: 70%">
                <p class="label_heading">Annular Slug Volume</p>
                <input
                  type="number"
                  id="valueColor"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  :value="annular_slug_volume"
                  maxlength="15"
                  @input="changeAnnulusSlugVolumeHandler($event)"
                  style="width: 95%"
                />
              </div>
              <div style="width: 30%">
                <UnitSelect
                  :display-id="displayId"
                  :widget-id="widgetId"
                  :mnemonic="'dmea'"
                  :pt="'pt-0'"
                  :category="'Volume'"
                  :is-custom-events="true"
                  :reverse="true"
                  :interChange="true"
                  :standaredUnit="
                    mappingDetails.annular_slug_volume_hyd.standard_units
                  "
                  :isMetrics="isWellMetrics"
                  :selected-val="
                    unitConversion?.annular_slug_volume_hyd?.unit &&
                    unitConversion?.annular_slug_volume_hyd?.unit != ''
                      ? unitConversion.annular_slug_volume_hyd.unit
                      :  isWellMetrics?mappingDetails.annular_slug_volume_hyd.METRIC:mappingDetails.annular_slug_volume_hyd.IMPERIAL
                  "
                  @change_unit_handler="
                    (val) => changeUnitHandler('annular_slug_volume_hyd', val)
                  "
                />
              </div>
            </div>
            <hr class="mt-2 mb-2 hrEleColor" />
            <p class="ml-2 text-xs textColorClass">Spotting Pill Input</p>
            <hr class="mt-2 mb-2 hrEleColor" />
            <div class="flex gap-2">
              <div class="flex pb-2 items-start flex-col" style="width: 70%">
                <p class="label_heading">
                  Spot pill height in Annulus from Bit
                </p>
                <input
                  type="number"
                  id="valueColor"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  :value="height_spot_column"
                  maxlength="15"
                  @input="changeSpotColumnHandler($event)"
                  style="width: 95%"
                />
              </div>
              <div style="width: 30%">
                <UnitSelect
                  :display-id="displayId"
                  :widget-id="widgetId"
                  :mnemonic="'dmea'"
                  :pt="'pt-0'"
                  :category="'Length'"
                  :is-custom-events="true"
                  :reverse="true"
                  :interChange="true"
                  :standaredUnit="
                    mappingDetails.height_spot_column_hyd.standard_units
                  "
                  :isMetrics="isWellMetrics"
                  :selected-val="
                    unitConversion?.height_spot_column_hyd?.unit &&
                    unitConversion?.height_spot_column_hyd?.unit != ''
                      ? unitConversion.height_spot_column_hyd.unit
                      :  isWellMetrics?mappingDetails.height_spot_column_hyd.METRIC:mappingDetails.height_spot_column_hyd.IMPERIAL
                  "
                  @change_unit_handler="
                    (val) => changeUnitHandler('height_spot_column_hyd', val)
                  "
                />
              </div>
            </div>
            <!-- <div class="flex flex-col mt-3">
              <p class="label_heading">Spot pill height in Annulus from Bit (FT)</p>
              <input
                type="number"
                id="valueColor"
                class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                :value="height_spot_column"
                maxlength="15"
                @input="changeSpotColumnHandler($event)"
              />
            </div> -->

            <!-- <div class="flex flex-col mt-3">
              <p class="label_heading">Washout Factor (%)</p>
              <input
                type="number"
                id="valueColor"
                class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                :value="washout_factor"
                maxlength="15"
                @input="changeWashoutFactorHandler($event)"
              />
            </div> -->
            <div class="flex gap-2">
              <div class="flex pb-2 items-start flex-col" style="width: 70%">
                <p class="label_heading">Washout Factor</p>
                <input
                  type="number"
                  id="valueColor"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  :value="washout_factor"
                  maxlength="15"
                  @input="changeWashoutFactorHandler($event)"
                  style="width: 95%"
                />
              </div>
              <div style="width: 30%">
                <UnitSelect
                  :display-id="displayId"
                  :widget-id="widgetId"
                  :mnemonic="'dmea'"
                  :pt="'pt-0'"
                  :category="'Percentage'"
                  :is-custom-events="true"
                  :reverse="true"
                  :interChange="true"
                  :standaredUnit="
                    mappingDetails.washout_factor_hyd.standard_units
                  "
                  :isMetrics="isWellMetrics"
                  :selected-val="
                    unitConversion?.washout_factor_hyd?.unit &&
                    unitConversion?.washout_factor_hyd?.unit != ''
                      ? unitConversion.washout_factor_hyd.unit
                      : isWellMetrics?mappingDetails.washout_factor_hyd.METRIC:mappingDetails.washout_factor_hyd.IMPERIAL
                  "
                  @change_unit_handler="
                    (val) => changeUnitHandler('washout_factor_hyd', val)
                  "
                />
              </div>
            </div>

            <!-- <div class="flex flex-col mt-3">
              <p class="label_heading">Pill/Slug Volume Left Inside Drill String (BBLS)</p>
              <input
                type="number"
                id="valueColor"
                class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                :value="drill_string_volume_left"
                maxlength="15"
                @input="changeDrillStringVolumeHandler($event)"
              />
            </div> -->
            <div class="flex gap-2">
              <div class="flex pb-2 items-start flex-col" style="width: 70%">
                <p class="label_heading">
                  Pill/Slug Volume Left Inside Drill String
                </p>
                <input
                  type="number"
                  id="valueColor"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  :value="drill_string_volume_left"
                  maxlength="15"
                  @input="changeDrillStringVolumeHandler($event)"
                  style="width: 95%"
                />
              </div>
              <div style="width: 30%">
                <UnitSelect
                  :display-id="displayId"
                  :widget-id="widgetId"
                  :mnemonic="'dmea'"
                  :pt="'pt-0'"
                  :category="'Volume'"
                  :is-custom-events="true"
                  :reverse="true"
                  :interChange="true"
                  :standaredUnit="
                    mappingDetails.drill_string_volume_left_hyd.standard_units
                  "
                  :isMetrics="isWellMetrics"
                  :selected-val="
                    unitConversion?.drill_string_volume_left_hyd?.unit &&
                    unitConversion?.drill_string_volume_left_hyd?.unit != ''
                      ? unitConversion.drill_string_volume_left_hyd.unit
                      : isWellMetrics?mappingDetails.drill_string_volume_left_hyd.METRIC:mappingDetails.drill_string_volume_left_hyd.IMPERIAL
                  "
                  @change_unit_handler="
                    (val) =>
                      changeUnitHandler('drill_string_volume_left_hyd', val)
                  "
                />
              </div>
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">
                Stroke Required Displace Surface (STKS)
              </p>
              <input
                type="number"
                id="valueColor"
                class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                :value="stroke_required_displace_surface"
                maxlength="15"
                @input="changeStrokeRequiredHandler($event)"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        v-on:click="toggle_height_handler_mudData"
        class="flex bg-black justify-between pt-5 pl-5 pb-4"
        :class="darkDark ? ' bg-right_' : 'bg-right_light'"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/shared_vision.png" alt="" />
            Mud Data
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureMud"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div> -->
      <!-- <div
        v-bind:style="{ display: isExpandFeatureMud ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="px-2 py-2">
          <div>
           
            <div class="flex items-center justify-between">
              <p class="label_heading">MW</p>
              <input
                style="z-index: 9999"
                type="text"
                class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"
                maxlength="15"
                @input="changeTitleHandler($event)"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>
            <h6 class="text-xxs p-2 text-center">Fann viscometer Results</h6>
            <div class="flex items-center justify-between pb-2">
              <p class="label_heading">0600</p>
              <input
                style="z-index: 9999"
                type="text"
                class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"
                maxlength="15"
                @input="changeTitleHandler($event)"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>
            <div class="flex items-center justify-between pb-2">
              <p class="label_heading">0300</p>
              <input
                style="z-index: 9999"
                type="text"
                class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"
                maxlength="15"
                @input="changeTitleHandler($event)"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>
            <div class="flex items-center justify-between pb-2">
              <p class="label_heading">0200</p>
              <input
                style="z-index: 9999"
                type="text"
                class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"
                maxlength="15"
                @input="changeTitleHandler($event)"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>
            <div class="flex items-center justify-between pb-2">
              <p class="label_heading">0100</p>
              <input
                style="z-index: 9999"
                type="text"
                class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"
                maxlength="15"
                @input="changeTitleHandler($event)"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>
            <div class="flex items-center justify-between pb-2">
              <p class="label_heading">e6</p>
              <input
                style="z-index: 9999"
                type="text"
                class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"
                maxlength="15"
                @input="changeTitleHandler($event)"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>
            <div class="flex items-center justify-between pb-2">
              <p class="label_heading">PV</p>
              <input
                style="z-index: 9999"
                type="text"
                class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"
                maxlength="15"
                @input="changeTitleHandler($event)"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>
            <div class="flex items-center justify-between pb-2">
              <p class="label_heading">YP</p>
              <input
                style="z-index: 9999"
                type="text"
                class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"
                maxlength="15"
                @input="changeTitleHandler($event)"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>
            <div class="flex items-center justify-between pb-2">
              <p class="label_heading">Slug Vol Pumped</p>
              <input
                style="z-index: 9999"
                type="text"
                class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"
                maxlength="15"
                @input="changeTitleHandler($event)"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>
            <div class="flex items-center justify-between pb-2">
              <p class="label_heading">Slug Weight</p>
              <input
                style="z-index: 9999"
                type="text"
                class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"
                maxlength="15"
                @input="changeTitleHandler($event)"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>
            <div class="flex items-center justify-between pb-2">
              <p class="label_heading">Drill Cutting Density</p>
              <input
                style="z-index: 9999"
                type="text"
                class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"
                maxlength="15"
                @input="changeTitleHandler($event)"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>
            <div class="flex items-center justify-between pb-2">
              <p class="label_heading">Cutting Partical Size</p>
              <input
                style="z-index: 9999"
                type="text"
                class="focus:ring-indigo-500 h-9 border-0 block w-5/12 rounded-md font-color"
                maxlength="15"
                @input="changeTitleHandler($event)"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-on:click="toggle_height_handler_BHA"
        class="flex bg-black justify-between pt-5 pl-5 pb-4"
        :class="darkDark ? ' bg-right_' : 'bg-right_light'"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/shared_vision.png" alt="" />
            BHA
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureBHA"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>
      <div
        v-bind:style="{ display: isExpandFeatureBHA ? '' : 'none' }"
        class="expand-right-sidebar-feature-one BHAlayout rounded"
      >
        <button v-on:click="toggle_height_handler_BHA" class="float-right p-2">
          <svg width="15" height="15">
            <path
              d="M 5,5 l 10,10 M 15,5 l -10,10"
              stroke="red"
              stroke-width="2"
            />
          </svg>
        </button>
        <div class="px-2 py-2">
          
          <div class="flex flex-col justify-between h-screen text-white">
            <header class="flex gap-10 pl-7 justify-around w-1/2">
              <button
                class="text-white text-xs font-medium bg-lime hover:bg-limeh rounded px-2 py-0.5 m-3"
              >
                Add BHA
              </button>
              <div class="selection flex items-center justify-between">
                <label class="block text-xs mr-2"> Select BHA : </label>
                <select class="select-hyd rounded">
                  <option>1</option>
                </select>
              </div>
              
            </header>
            <main class="px-2 flex-grow overflow-auto">
              <table class="border w-full">
                <thead>
                  <tr class="sticky top-0 text-xxs">
                    <th></th>
                    <th class="border">Item #</th>
                    <th class="border">Tool Group</th>
                    <th class="border">Tool Type</th>
                    <th class="border">SerialNumber</th>
                    <th class="border">Description</th>
                    <th class="border">ID (In.)</th>
                    <th class="border">OD (In.)</th>
                    <th class="border">Length (Ft)</th>
                    <th class="border">Top connection</th>
                    <th class="border">Bottom connection</th>
                    <th class="border">Stab OD Face</th>
                    <th class="border">Fishing Neck (Ft)</th>
                    <th class="border">Weight (Ibf)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="border">
                      <span class="text-xxs text-lime px-1" @click="addRow"
                        >Add</span
                      ><span class="text-xxs text-red px-1">Delete</span>
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                      />
                    </td>
                  </tr>
                  <tr v-for="rown in rows" :key="rown">
                    <td class="border">
                      <span class="text-xxs text-lime px-1" @click="addRow"
                        >Add</span
                      ><span class="text-xxs text-red px-1">Delete</span>
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        v-model="rown.item"
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        v-model="rown.toolgp"
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        v-model="rown.tooltyp"
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        v-model="rown.sn"
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        v-model="rown.discription"
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        v-model="rown.id"
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        v-model="rown.od"
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        v-model="rown.length"
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        v-model="rown.topconn"
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        v-model="rown.bormconn"
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        v-model="rown.stapod"
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        v-model="rown.fishingneck"
                      />
                    </td>
                    <td class="border">
                      <input
                        class="pmpinp text-xxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                        id=""
                        v-model="rown.weight"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </main>
          </div>
        </div>
      </div> -->
    </div>
    <UpDownButtons
      :isExpandFeatureSix="isExpandFeatureSix"
      :toggle_height_handler_six="toggle_height_handler_six"
      :id="id"
      :displayId="properties.displayId"
      :widgetId="properties.widgetId"
      :closePropertyFile="closeNavigation"
    />
  </nav>
</template>

<script>
import UpDownButtons from "../UpDownButtons/UpDownButtons";
import UnitSelect from "../Navbars/properties/unitSelectDirectional.vue";
import mapping from "../Widgets/HydraulicsCalculator/mapping.json";
export default {
  name: "hydraulics-properties",
  components: {
    UpDownButtons,
    UnitSelect,
  },
  props: {
    displayId: String,
    widgetId: String,
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: [Number, String],
    properties: {
      default: {},
    },
    closeNavigation: Function,
  },
  data() {
    return {
      isExpandFeatureOne: true,
      isExpandFeatureTwo: false,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureFive: false,
      isExpandFeatureSix: false,
      isExpandFeatureMud: false,
      isExpandFeatureBHA: false,
      isExpandFeatureSlug: true,
      rows: [],
      idx: -1,
      mappingDetails: mapping,
      isWellMetrics:false
      // title: this.properties.title,
    };
  },
  methods: {
    changeUnitHandler(key, factor) {
      const fact = {
        unit: factor.to_unit,
        conversion_factor: factor.conversion_factor,
      };
      this.$store.dispatch("rect/changSwabSearchUnit", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        key: key,
        value: fact,
      });
    },
    // Slug calculation props handler
    changeDrillPipeLengthHandler(e) {
      this.$store.dispatch("rect/changeDrillPipeLength", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeSlugDrillPipeLengthHandler(e) {
      this.$store.dispatch("rect/changeSlugDrillPipeLength", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeSlugWeightHandler(e) {
      this.$store.dispatch("rect/changeSlugWeight", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeAnnulusVolumeHandler(e) {
      this.$store.dispatch("rect/changeAnnulusVolume", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeSpotColumnHandler(e) {
      this.$store.dispatch("rect/changeSpotColumn", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeAnnulusSlugVolumeHandler(e) {
      this.$store.dispatch("rect/changeAnnulusSlugVolume", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeSlugVolumeHandler(e) {
      this.$store.dispatch("rect/changeSlugVolume", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeWashoutFactorHandler(e) {
      this.$store.dispatch("rect/changeWashoutFactor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeDrillStringVolumeHandler(e) {
      this.$store.dispatch("rect/changeDrillStringVolume", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeStrokeRequiredHandler(e) {
      this.$store.dispatch("rect/changeStrokeRequired", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    // End
    changeTitleHandler(e) {
      this.$store.dispatch("rect/changeTitle", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },

    colorValueHandler(e) {
      this.$store.dispatch("rect/changeTitleColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    toggleAutoHandler() {
      this.$store.dispatch("rect/toggleAutoFit", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    toggleBorderVisibiltyHandler() {
      this.$store.dispatch("rect/toggleBorderVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },

    toggleTitleVisibiltyHandler() {
      this.$store.dispatch("rect/toggleTitleVisibility", {
        displayId: this.displayId,
        id: this.id,
      });
    },

    fontValueHandler(e) {
      this.$store.dispatch("rect/titleFontValue", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value ? parseInt(e.target.value) : 0,
      });
    },

    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },

    toggle_height_handler_mudData() {
      if (this.isExpandFeatureMud == true) {
        this.isExpandFeatureMud = false;
      } else {
        this.isExpandFeatureMud = true;
      }
    },
    toggle_height_handler_slug() {
      if (this.isExpandFeatureSlug == true) {
        this.isExpandFeatureSlug = false;
      } else {
        this.isExpandFeatureSlug = true;
      }
    },
    toggle_height_handler_BHA() {
      if (this.isExpandFeatureBHA == true) {
        this.isExpandFeatureBHA = false;
      } else {
        this.isExpandFeatureBHA = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_five() {
      if (this.isExpandFeatureFive == true) {
        this.isExpandFeatureFive = false;
      } else {
        this.isExpandFeatureFive = true;
      }
    },
    toggle_height_handler_six() {
      if (this.isExpandFeatureSix == true) {
        this.isExpandFeatureSix = false;
      } else {
        this.isExpandFeatureSix = true;
      }
    },
    dark() {
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      this.darkMode = false;
      this.$emit("light");
    },
    // -------  ADD ROW FUNCTION -------
    addRow: function () {
      this.rows.push({ pump: "", displacemnt: "" });
    },
  },
  beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
  },
  mounted() {
    const dtls = this.$store.state.disp.displays[this.displayId];
    console.log('cal==',dtls);
    if (
      dtls != null &&
      dtls.wellId != null &&
      dtls.wellboreId != null &&
      dtls.wellboreState != null
    ) {
      this.isWellMetrics = dtls.unit_conversion;
    }
  },
  computed: {
    unitConversion() {
      return this.$store.state.rect.rects[this.idx].unitConversion;
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    height_spot_column() {
      return this.$store.state.rect.rects[this.idx].height_spot_column || "";
    },
    dry_pipe_length() {
      return this.$store.state.rect.rects[this.idx].dry_pipe_length || "";
    },
    slug_volume() {
      return this.$store.state.rect.rects[this.idx].slug_volume || "";
    },
    slug_dry_pipe_length() {
      return this.$store.state.rect.rects[this.idx].slug_dry_pipe_length || "";
    },
    slug_weight() {
      return this.$store.state.rect.rects[this.idx].slug_weight || "";
    },
    annular_capacity() {
      return this.$store.state.rect.rects[this.idx].annular_capacity || "";
    },
    annular_slug_volume() {
      return this.$store.state.rect.rects[this.idx].annular_slug_volume || "";
    },
    washout_factor() {
      return this.$store.state.rect.rects[this.idx].washout_factor || "";
    },
    drill_string_volume_left() {
      return (
        this.$store.state.rect.rects[this.idx].drill_string_volume_left || ""
      );
    },
    stroke_required_displace_surface() {
      return (
        this.$store.state.rect.rects[this.idx]
          .stroke_required_displace_surface || ""
      );
    },
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css"></style>
